import {
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_REQUEST_FAILURE,
  ACTIVATE_ACCOUNT_REQUEST_SUCCESS,
  ADD_FILE__REQUEST_FAILURE,
  ADD_FILE_REQUEST,
  ADD_FILE_REQUEST_SUCCESS,
  COMPLETE_ONBOARD,
  COMPLETE_ONBOARD_FAILURE,
  COMPLETE_ONBOARD_SUCCESS,
  CURRENT_ACCOUNT_REQUEST,
  CURRENT_ACCOUNT_REQUEST_FAILURE,
  CURRENT_ACCOUNT_REQUEST_SUCCESS,
  CURRENT_PROFILE_REQUEST,
  CURRENT_PROFILE_REQUEST_FAILURE,
  CURRENT_PROFILE_REQUEST_SUCCESS,
  DISMISS_REGISTER_SUCCESSFUL_FORM,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  LOGIN_REQUEST_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_REQUEST_FAILURE,
  REGISTER_REQUEST_SUCCESS,
  REMOVE_USER_FILES_REQUEST,
  REMOVE_USER_FILES_REQUEST_FAILURE,
  REMOVE_USER_FILES_REQUEST_SUCCESS,
  RESET_PASSWORD_CONFIRM_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_CONFIRM_FAILURE,
  RESET_PASSWORD_REQUEST_CONFIRM_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
  RESET_PASSWORD_REQUEST_SUCCESS,
  UPDATE_CURRENT_PROFILE_REQUEST,
  UPDATE_CURRENT_PROFILE_REQUEST_FAILURE,
  UPDATE_CURRENT_PROFILE_REQUEST_SUCCESS,
} from "./actions";

const initialState = {
  isLoading: false,
  errors: {},
  activation: {},
  login: {},
  reset: {},
  currentUser: {},
  currentProfile: {
    addFile: {
      IsLoading: false,
    },
    removeFile: {},
    files: [],
  },
  onboarding: {
    isLoading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        login: {
          isLoading: true,
        },
      };
    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        login: {
          ...action.payload,
          isLoading: false,
          loginSuccessful: true,
        },
      };
    case LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        login: {
          ...action.payload,
          isLoading: false,
          loginSuccessful: false,
        },
      };

    case REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REGISTER_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        errors: {
          registration: [],
        },
        isLoading: false,
        registrationSuccessful: true,
      };
    case REGISTER_REQUEST_FAILURE:
      return {
        ...state,
        errors: {
          registration: action.payload,
        },
        isLoading: false,
      };
    case DISMISS_REGISTER_SUCCESSFUL_FORM:
      return {
        ...state,
        registrationSuccessful: false,
      };

    case RESET_PASSWORD_REQUEST:
    case RESET_PASSWORD_CONFIRM_REQUEST:
      return {
        ...state,
        reset: {
          isLoading: true,
        },
      };
    case RESET_PASSWORD_REQUEST_SUCCESS:
    case RESET_PASSWORD_REQUEST_CONFIRM_SUCCESS:
      return {
        ...state,
        reset: {
          ...action.payload,
          isLoading: false,
          resetSuccessful: true,
        },
      };
    case RESET_PASSWORD_REQUEST_FAILURE:
    case RESET_PASSWORD_REQUEST_CONFIRM_FAILURE:
      return {
        ...state,
        reset: {
          ...action.payload,
          isLoading: false,
          resetSuccessful: false,
        },
      };

    case ACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        activation: {
          isLoading: true,
          message: "Activating account...",
        },
      };
    case ACTIVATE_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        activation: {
          isLoading: false,
          accountActivated: true,
          ...action.payload,
        },
      };
    case ACTIVATE_ACCOUNT_REQUEST_FAILURE:
      return {
        ...state,
        activation: {
          ...action.payload,
          isLoading: false,
          accountActivationFailed: true,
        },
      };

    case CURRENT_ACCOUNT_REQUEST:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: true,
        },
      };
    case CURRENT_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        currentUser: {
          isLoading: false,
          ...action.payload,
          requestSuccessful: true,
        },
      };
    case CURRENT_ACCOUNT_REQUEST_FAILURE:
      return {
        ...state,
        currentUser: {
          isLoading: false,
          ...action.payload,
          requestSuccessful: false,
        },
      };

    case CURRENT_PROFILE_REQUEST:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          isLoading: true,
        },
      };
    case CURRENT_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        currentProfile: {
          isLoading: false,
          ...action.payload,
          requestSuccessful: true,
        },
      };
    case CURRENT_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        currentProfile: {
          isLoading: false,
          errors: action.payload,
          requestSuccessful: false,
        },
      };

    case UPDATE_CURRENT_PROFILE_REQUEST:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          isUpdating: true,
        },
      };
    case UPDATE_CURRENT_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        currentProfile: {
          isUpdating: false,
          ...action.payload,
          updateSuccessful: true,
        },
      };
    case UPDATE_CURRENT_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          isUpdating: false,
          updateSuccessful: false,
          errors: {
            ...action.payload,
          },
        },
      };

    case ADD_FILE_REQUEST:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          addFile: {
            isLoading: true,
          },
        },
      };
    case ADD_FILE_REQUEST_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let updatedFiles = state.currentProfile.files;
      if (action.payload.details.removedIds.length) {
        // remove the file if it has already been deleted
        updatedFiles = state.currentProfile.files.filter((file) => {
          return !action.payload.details.removedIds.includes(file.id);
        });
      }
      updatedFiles = [...updatedFiles, action.payload];
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          addFile: {
            isLoading: false,
            uploadSuccess: true,
          },
          files: updatedFiles,
        },
      };
    case ADD_FILE__REQUEST_FAILURE:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          addFile: {
            isLoading: false,
            uploadSuccess: false,
          },
        },
      };

    case REMOVE_USER_FILES_REQUEST:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          removeFile: {
            [action.payload.id]: {
              isLoading: true,
              ...action.payload,
            },
          },
        },
      };
    case REMOVE_USER_FILES_REQUEST_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { files } = state.currentProfile;

      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          removeFile: {
            [action.payload.id]: {
              isLoading: false,
              deleted: true,
            },
          },
          files: files ? files.filter((file) => file.id !== action.payload.id) : files,
        },
      };
    case REMOVE_USER_FILES_REQUEST_FAILURE:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          removeFile: {
            [action.payload.id]: {
              isLoading: false,
              deleted: false,
            },
          },
        },
      };

    case COMPLETE_ONBOARD:
      return {
        ...state,
        onboarding: {
          isLoading: true,
        },
      };
    case COMPLETE_ONBOARD_SUCCESS:
      return {
        ...state,
        onboarding: {
          isLoading: false,
        },
        currentUser: action.payload.user,
      };
    case COMPLETE_ONBOARD_FAILURE:
      return {
        ...state,
        onboarding: {
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
