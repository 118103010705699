import DEMO from "./constant";
import { error } from "@pnotify/core";
import Cookies from "universal-cookie";

export function error500Notify() {
  error({
    title: "Error Notice",
    text: DEMO.GENERIC_ERROR,
  });
}

export function connectionErrorNotify() {
  error({
    title: "Error Notice",
    text: `We are having trouble communicating with one of services. Please try again later`,
  });
}

export function genericErrorHandler(dispatch, action, error) {
  if (error.response) {
    if (error.response.data) {
      dispatch(action(error.response.data));
    } else {
      dispatch(action());
    }
    if (error.response.status === 500) {
      error500Notify();
    }
  } else {
    // this will happen when there is a response
    connectionErrorNotify();
    dispatch(action());
  }
}

export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
  return script;
};

export function truncate(n, len) {
  //Truncate file names. if the filename has no extension then just append an empty string
  let ext;
  try {
    ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
  } catch (e) {
    ext = "";
  }
  let filename = n.replace("." + ext, "");
  if (filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? "[...]" : "");
  return filename + "." + ext;
}

export function objectFlip(obj) {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
}

export const cookies = new Cookies();
