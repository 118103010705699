import axios from "axios";
import axiosApiInstance, { redirectToLogin } from "./../global/axios";

import DEMO from "../global/constant";
import { cookies, genericErrorHandler } from "../global/common";
import { error, info, success } from "@pnotify/core";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_REQUEST_SUCCESS = "REGISTER_REQUEST_SUCCESS";
export const REGISTER_REQUEST_FAILURE = "REGISTER_REQUEST_FAILURE";
export const DISMISS_REGISTER_SUCCESSFUL_FORM = "DISMISS_REGISTER_SUCCESSFUL_FORM";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_FAILURE = "RESET_PASSWORD_REQUEST_FAILURE";

export const ACTIVATE_ACCOUNT_REQUEST = "ACTIVATE_ACCOUNT_REQUEST";
export const ACTIVATE_ACCOUNT_REQUEST_SUCCESS = "ACTIVATE_ACCOUNT_REQUEST_SUCCESS";
export const ACTIVATE_ACCOUNT_REQUEST_FAILURE = "ACTIVATE_ACCOUNT_REQUEST_FAILURE";

export const CURRENT_ACCOUNT_REQUEST = "CURRENT_ACCOUNT_REQUEST";
export const CURRENT_ACCOUNT_REQUEST_SUCCESS = "CURRENT_ACCOUNT_REQUEST_SUCCESS";
export const CURRENT_ACCOUNT_REQUEST_FAILURE = "CURRENT_ACCOUNT_REQUEST_FAILURE";

export const CURRENT_PROFILE_REQUEST = "CURRENT_PROFILE_REQUEST";
export const CURRENT_PROFILE_REQUEST_SUCCESS = "CURRENT_PROFILE_REQUEST_SUCCESS";
export const CURRENT_PROFILE_REQUEST_FAILURE = "CURRENT_PROFILE_REQUEST_FAILURE";

export const UPDATE_CURRENT_PROFILE_REQUEST = "UPDATE_CURRENT_PROFILE_REQUEST";
export const UPDATE_CURRENT_PROFILE_REQUEST_SUCCESS = "UPDATE_CURRENT_PROFILE_REQUEST_SUCCESS";
export const UPDATE_CURRENT_PROFILE_REQUEST_FAILURE = "UPDATE_CURRENT_PROFILE_REQUEST_FAILURE";

function loginRequest(payload) {
  return {
    type: LOGIN_REQUEST,
    payload,
  };
}

function loginRequestSuccess(payload) {
  return {
    type: LOGIN_REQUEST_SUCCESS,
    payload,
  };
}

function loginRequestFailed(payload) {
  return {
    type: LOGIN_REQUEST_FAILURE,
    payload,
  };
}

function registerRequest(payload) {
  return {
    type: REGISTER_REQUEST,
    payload,
  };
}

function registerRequestSuccess(payload) {
  return {
    type: REGISTER_REQUEST_SUCCESS,
    payload,
  };
}

function registerRequestFailed(payload) {
  return {
    type: REGISTER_REQUEST_FAILURE,
    payload,
  };
}

export function registrationSuccessful() {
  return {
    type: DISMISS_REGISTER_SUCCESSFUL_FORM,
  };
}

function activateAccountRequest(payload) {
  return {
    type: ACTIVATE_ACCOUNT_REQUEST,
    payload,
  };
}

function activateAccountRequestSuccess(payload) {
  return {
    type: ACTIVATE_ACCOUNT_REQUEST_SUCCESS,
    payload,
  };
}

function activateAccountRequestFailed(payload) {
  return {
    type: ACTIVATE_ACCOUNT_REQUEST_FAILURE,
    payload,
  };
}

function currentAccountRequest(payload) {
  return {
    type: CURRENT_ACCOUNT_REQUEST,
    payload,
  };
}

function currentAccountRequestSuccess(payload) {
  return {
    type: CURRENT_ACCOUNT_REQUEST_SUCCESS,
    payload,
  };
}

function currentAccountRequestFailed(payload) {
  return {
    type: CURRENT_ACCOUNT_REQUEST_FAILURE,
    payload,
  };
}

function currentProfileRequest(payload) {
  return {
    type: CURRENT_PROFILE_REQUEST,
    payload,
  };
}

function currentProfileRequestSuccess(payload) {
  return {
    type: CURRENT_PROFILE_REQUEST_SUCCESS,
    payload,
  };
}

function currentProfileRequestFailed(payload) {
  return {
    type: CURRENT_PROFILE_REQUEST_FAILURE,
    payload,
  };
}

function updateCurrentProfileRequest(payload) {
  return {
    type: UPDATE_CURRENT_PROFILE_REQUEST,
    payload,
  };
}

function updateCurrentProfileRequestSuccess(payload) {
  return {
    type: UPDATE_CURRENT_PROFILE_REQUEST_SUCCESS,
    payload,
  };
}

function updateCurrentProfileRequestFailed(payload) {
  return {
    type: UPDATE_CURRENT_PROFILE_REQUEST_FAILURE,
    payload,
  };
}

export const registerUser = (payload) => async (dispatch, getState) => {
  const { auth } = getState();
  if (!auth.isLoading) {
    dispatch(registerRequest(payload));
    try {
      const response = await axios.post(`${DEMO.BASE_URL}/accounts/users/`, payload);

      if (response && response.data) {
        if (response.status === 201) {
          dispatch(registerRequestSuccess(response.data));
          success({
            title: "Message",
            text: "Your account has been created successfully. ",
          });
        }
      } else {
        dispatch(registerRequestFailed());
      }
    } catch (e) {
      if (e.response) {
        genericErrorHandler(dispatch, registerRequestFailed, e);
      }
    }
  }
};

export const activateUser = (payload) => async (dispatch) => {
  dispatch(activateAccountRequest(payload));
  let message = "";
  try {
    const response = await axios.post(`${DEMO.BASE_URL}/accounts/users/activation/`, payload);
    if (response && response.status === 204) {
      message = "Your account has been activated successfully. ";
      dispatch(activateAccountRequestSuccess({ message }));
      success({
        title: "Message",
        text: message,
      });
    }
  } catch (e) {
    if (e.response) {
      if (e.response.status === 403) {
        message = "Your account has already been activated. Please proceed to login";
        error({
          title: "Error",
          text: message,
        });
        dispatch(activateAccountRequestFailed({ ...e.response.data, message }));
      } else if (e.response.status === 400) {
        message =
          "There was an error activating your account." +
          " Please check that the url matches the one we sent you ";
        error({
          title: "Error",
          text: message,
        });
        dispatch(activateAccountRequestFailed({ ...e.response.data, message }));
      } else {
        genericErrorHandler(dispatch, activateAccountRequestFailed, e);
      }
    }
  }
};

export const loginUser = (payload) => async (dispatch, getState) => {
  const { auth } = getState();
  if (!auth.login.isLoading) {
    let message = "";
    dispatch(loginRequest(payload));
    try {
      const response = await axios.post(`${DEMO.BASE_URL}/accounts/token/`, payload);
      if (response && response.status === 200) {
        message = "login successful";
        dispatch(loginRequestSuccess({ ...response.status.data, message }));
        success({
          title: "Welcome",
          text: message,
        });

        cookies.set(DEMO.ACCESS_TOKEN_NAME, response.data.access, { path: "/" });
        cookies.set(DEMO.REFRESH_TOKEN_NAME, response.data.refresh, { path: "/" });
      }
    } catch (e) {
      message = "There was an error logging you in. ";
      error({
        title: "Error",
        text: message,
      });
      if (e.response) {
        if (e.response.status === 400) {
          message =
            "Something has gone somewhat wrong. We are aware and will be working to fix it." +
            " Please contact us if the issue persists";
          dispatch(loginRequestFailed({ ...e.response.status.data, message }));
        } else if (e.response.status === 401) {
          message = "Your access credentials are invalid.Please try again";
          dispatch(loginRequestFailed({ ...e.response.data, message }));
        } else {
          genericErrorHandler(dispatch, loginRequestFailed, e);
        }
      } else {
        genericErrorHandler(dispatch, loginRequestFailed, e);
      }
    }
  }
};

export const getCurrentUser = () => async (dispatch, getState) => {
  const { auth } = getState();
  if (!auth.currentUser.isLoading) {
    dispatch(currentAccountRequest());
    try {
      const response = await axiosApiInstance.get(`${DEMO.BASE_URL}/accounts/users/me/`);
      if (response && response.status === 200) {
        dispatch(currentAccountRequestSuccess(response.data));
      } else {
        dispatch(currentAccountRequestFailed());
        if (response.status === 401) {
          redirectToLogin(true);
        }
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          redirectToLogin(true);
        } else if (e.response.status === 500) {
          genericErrorHandler(dispatch, currentAccountRequestFailed, e);
        }
      } else {
        dispatch(currentAccountRequestFailed());
      }
    }
  }
};

export const getCurrentProfile = () => async (dispatch, getState) => {
  const {
    auth: { currentUser, currentProfile },
  } = getState();
  if (currentUser.id && !currentProfile.isLoading && currentProfile.user !== currentUser.id) {
    dispatch(currentProfileRequest());
    try {
      const response = await axiosApiInstance.get(
        `${DEMO.BASE_URL}/accounts/profile/${currentUser.id}/`
      );

      if (response && response.status === 200) {
        dispatch(currentProfileRequestSuccess(response.data));
      } else {
        dispatch(currentProfileRequestFailed(response.data || {}));
      }
    } catch (e) {
      dispatch(currentProfileRequestFailed());
    }
  }
};

export const updateCurrentProfile = (payload) => async (dispatch, getState) => {
  const {
    auth: { currentUser, currentProfile },
  } = getState();
  if (currentUser.id && !currentProfile.isLoading && currentUser.user !== currentUser.id) {
    dispatch(updateCurrentProfileRequest(payload));
    try {
      const response = await axiosApiInstance.patch(
        `${DEMO.BASE_URL}/accounts/profile/${currentUser.id}/`,
        payload
      );
      if (response && response.status === 200) {
        dispatch(updateCurrentProfileRequestSuccess(response.data));
        success({
          title: "Message",
          text: "Your profile has been updated successfully",
        });
      } else {
        dispatch(updateCurrentProfileRequestFailed(response.data || {}));
        //TODO this is temporary do better error message by moving this in form
        let message = "Something went wrong and your profile has not been updated successfully. ";

        if (response.data.specialisation) {
          message = message + response.data.specialisation[0];
        }
        error({
          title: "Message",
          text: message,
        });
      }
    } catch (e) {
      dispatch(updateCurrentProfileRequestFailed());
    }
  }
};

export const ADD_FILE_REQUEST = "ADD_FILE_REQUEST";
export const ADD_FILE_REQUEST_SUCCESS = "ADD_FILE_REQUEST_SUCCESS";
export const ADD_FILE__REQUEST_FAILURE = "ADD_FILE__REQUEST_FAILURE";

function addFileRequest(payload) {
  return {
    type: ADD_FILE_REQUEST,
    payload,
  };
}

function addFileRequestSuccess(payload) {
  return {
    type: ADD_FILE_REQUEST_SUCCESS,
    payload,
  };
}

function addFileRequestFailed(payload) {
  return {
    type: ADD_FILE__REQUEST_FAILURE,
    payload,
  };
}

export const addNewFile = (payload) => async (dispatch, getState) => {
  const {
    auth: {
      currentUser,
      currentProfile: { addFile },
    },
  } = getState();
  if (currentUser.id && (!addFile || !addFile.isLoading)) {
    try {
      dispatch(addFileRequest());
      let data = new FormData();
      data.append("file", payload.file);
      data.append("file_type", payload.docType);
      data.append("user", currentUser.id);

      const response = await axios.post(`${DEMO.BASE_URL}/accounts/profile/files/`, data);
      if (response && response.data) {
        if (response.status === 201) {
          dispatch(addFileRequestSuccess(response.data));
          success({
            title: "Message",
            text: `${payload.file.name} has been uploaded successfully`,
          });
        }
      }
    } catch (e) {
      dispatch(addFileRequestFailed());
      error({
        title: "Error",
        text: "We are unable to upload your file. Please try again",
      });
    }
  } else {
    error({
      title: "Error",
      text:
        "We are unable to upload your file right now since there is a pending operation. Please try again",
    });
  }
};

function resetPasswordRequest(payload) {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload,
  };
}

function resetPasswordRequestSuccess(payload) {
  return {
    type: RESET_PASSWORD_REQUEST_SUCCESS,
    payload,
  };
}

function resetPasswordRequestFailed(payload) {
  return {
    type: REGISTER_REQUEST_FAILURE,
    payload,
  };
}

export const resetPassword = (email) => async (dispatch, getState) => {
  const {
    auth: { reset },
  } = getState();
  if (!reset.isLoading) {
    dispatch(resetPasswordRequest({ email }));
    try {
      const response = await axios.post(`${DEMO.BASE_URL}/accounts/users/reset_password/`, {
        email,
      });
      if (response.status === 204) {
        dispatch(resetPasswordRequestSuccess(response.data));
        success({
          title: "Message",
          text: "A recovery email has been sent to you successfully.",
        });
      } else {
        dispatch(resetPasswordRequestFailed(response.data));
        error({
          title: "Message",
          text: "A recovery email has not been sent to you successfully. something went wrong",
        });
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 500) {
          genericErrorHandler(dispatch, currentAccountRequestFailed, e);
        } else {
          dispatch(resetPasswordRequestFailed(e.response.data));
        }
      } else {
        dispatch(resetPasswordRequestFailed());
      }
    }
  } else {
    error({
      title: "Message",
      text: "There is already a pending reset password request",
    });
  }
};

export const RESET_PASSWORD_CONFIRM_REQUEST = "RESET_PASSWORD_CONFIRM_REQUEST";
export const RESET_PASSWORD_REQUEST_CONFIRM_SUCCESS = "RESET_PASSWORD_REQUEST_CONFIRM_SUCCESS";
export const RESET_PASSWORD_REQUEST_CONFIRM_FAILURE = "RESET_PASSWORD_REQUEST_CONFIRM_FAILURE";

function resetPasswordConfirmRequest(payload) {
  return {
    type: RESET_PASSWORD_CONFIRM_REQUEST,
    payload,
  };
}

function resetPasswordRequestConfirmSuccess(payload) {
  return {
    type: RESET_PASSWORD_REQUEST_CONFIRM_SUCCESS,
    payload,
  };
}

function resetPasswordRequestConfirmFailed(payload) {
  return {
    type: RESET_PASSWORD_REQUEST_CONFIRM_FAILURE,
    payload,
  };
}

export const resetPasswordConfirm = (payload) => async (dispatch, getState) => {
  const {
    auth: { reset },
  } = getState();
  if (!reset.isLoading) {
    dispatch(resetPasswordConfirmRequest);
    try {
      const response = await axios.post(
        `${DEMO.BASE_URL}/accounts/users/reset_password_confirm/`,
        payload
      );

      if (response && response.status === 204) {
        dispatch(resetPasswordRequestConfirmSuccess());
        success({
          title: "Message",
          text: "Your password has been reset successfully",
        });
      } else {
        dispatch(resetPasswordRequestConfirmFailed());
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        dispatch(resetPasswordRequestConfirmFailed(e.response.data));
        error({
          title: "Error",
          text: "There was an error resetting you password",
        });
      } else {
        genericErrorHandler(dispatch, resetPasswordRequestConfirmFailed, e);
      }
    }
  }
};

export const REMOVE_USER_FILES_REQUEST = "REMOVE_USER_FILES_REQUEST";
export const REMOVE_USER_FILES_REQUEST_SUCCESS = "REMOVE_USER_FILES_REQUEST_SUCCESS";
export const REMOVE_USER_FILES_REQUEST_FAILURE = "REMOVE_USER_FILES_REQUEST_FAILURE";

function removeUserFilesRequest(payload) {
  return {
    type: REMOVE_USER_FILES_REQUEST,
    payload,
  };
}

function removeUserFilesSuccess(payload) {
  return {
    type: REMOVE_USER_FILES_REQUEST_SUCCESS,
    payload,
  };
}

function removeUserFilesFailed(payload) {
  return {
    type: REMOVE_USER_FILES_REQUEST_FAILURE,
    payload,
  };
}

export const removeUserFile = ({ id, name }) => async (dispatch, getState) => {
  const {
    auth: { currentProfile },
  } = getState();
  const isLoading = currentProfile.removeFile
    ? currentProfile.removeFile[id]
      ? !currentProfile.removeFile[id].isLoading
      : false
    : false;
  const exists = currentProfile.files
    ? !!currentProfile.files.map((file) => file.id === id)
    : false;
  if (!isLoading && exists) {
    dispatch(removeUserFilesRequest({ id }));
    const error = () => {
      dispatch(removeUserFilesFailed({ id }));
      error({
        title: "message",
        text: `${name} has not been deleted successfully`,
      });
    };

    try {
      const response = await axios.delete(`${DEMO.BASE_URL}/accounts/profile/files/${id}/`);

      if (response && response.status === 204) {
        dispatch(removeUserFilesSuccess({ id }));
        success({
          title: "message",
          text: `${name} has been deleted successfully`,
        });
      } else {
        error();
      }
    } catch (e) {
      if (e.response) {
        error();
      } else {
        genericErrorHandler(dispatch, removeUserFilesFailed, e);
      }
    }
  } else {
    info({
      title: "message",
      text: "Deletion already in progress",
    });
  }
};

export const UPDATE_USER_PROFILE_STATUS_REQUEST = "UPDATE_USER_PROFILE_STATUS_REQUEST";
export const UPDATE_USER_PROFILE_STATUS_REQUEST_SUCCESS =
  "UPDATE_USER_PROFILE_STATUS_REQUEST_SUCCESS";
export const UPDATE_USER_PROFILE_STATUS_REQUEST_FAILURE =
  "UPDATE_USER_PROFILE_STATUS_REQUEST_FAILURE";

function updateUserProfileRequest(payload) {
  return {
    type: UPDATE_USER_PROFILE_STATUS_REQUEST,
    payload,
  };
}

function updateUserProfileSuccess(payload) {
  return {
    type: UPDATE_USER_PROFILE_STATUS_REQUEST_SUCCESS,
    payload,
  };
}

function updateUserProfileFailed(payload) {
  return {
    type: UPDATE_USER_PROFILE_STATUS_REQUEST_FAILURE,
    payload,
  };
}

export const updateStatus = (payload) => async (dispatch, getState) => {
  const {
    admin: { updateUser },
  } = getState();
  if (!updateUser.isLoading) {
    try {
      dispatch(updateUserProfileRequest(payload));
      const response = await axios.post(
        `${DEMO.BASE_URL}/accounts/profile/update_status/`,
        payload
      );

      if (response && response.status === 200) {
        dispatch(updateUserProfileSuccess(response.data));
        info({
          title: "Message",
          text: "Profile updated successfully",
        });
      }
    } catch (e) {
      if (e.response) {
        dispatch(updateUserProfileFailed(e.response.data));
      } else {
        genericErrorHandler(dispatch, updateUserProfileFailed, e);
      }
      error({
        title: "Error",
        text: "The profile was not updated successfully",
      });
    }
  }
};

export const COMPLETE_ONBOARD = "COMPLETE_ONBOARD";
export const COMPLETE_ONBOARD_SUCCESS = "COMPLETE_ONBOARD_SUCCESS";
export const COMPLETE_ONBOARD_FAILURE = "COMPLETE_ONBOARD_FAILURE";

function completeOnboard(payload) {
  return {
    type: COMPLETE_ONBOARD,
    payload,
  };
}

function completeOnboardSuccess(payload) {
  return {
    type: COMPLETE_ONBOARD_SUCCESS,
    payload,
  };
}

function completeOnboardFailure(payload) {
  return {
    type: COMPLETE_ONBOARD_FAILURE,
    payload,
  };
}

export const updateOnboard = (payload) => async (dispatch, getState) => {
  const {
    auth: { currentUser, onboarding },
  } = getState();

  if (!onboarding.isLoading) {
    try {
      dispatch(completeOnboard(payload));
      const response = await axiosApiInstance.post(
        `${DEMO.BASE_URL}/accounts/profile/${currentUser.id}/onboard_step/`,
        payload
      );

      if (response && response.status === 200) {
        dispatch(completeOnboardSuccess(response.data));
        success({
          title: "Message",
          text: response.data.message,
        });
      } else {
        dispatch(completeOnboardFailure(response.data || {}));
        error({
          title: "Operation Failed",
          text: response.data.message,
        });
      }
    } catch (e) {
      if (e.response) {
        dispatch(completeOnboardFailure(e.response.data));
        error({
          title: "Operation Failed",
          text: e.response.data.message,
        });
      } else {
        genericErrorHandler(dispatch, completeOnboardFailure, e);
      }
    }
  }
};
