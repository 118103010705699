import {
  ADMIN_MODE,
  LIST_USER_INVITATIONS,
  LIST_USER_INVITES_REQUEST_FAILURE,
  LIST_USER_INVITES_REQUEST_SUCCESS,
  SEND_USER_INVITES,
  SEND_USER_INVITES_REQUEST_FAILURE,
  SEND_USER_INVITES_REQUEST_SUCCESS,
  UPDATE_USER_INVITES,
  UPDATE_USER_INVITES_REQUEST_FAILURE,
  UPDATE_USER_INVITES_REQUEST_SUCCESS,
  USER_PROFILE_REQUEST,
  USER_PROFILE_REQUEST_FAILURE,
  USER_PROFILE_REQUEST_SUCCESS,
  USERS_LIST_REQUEST,
  USERS_LIST_REQUEST_FAILURE,
  USERS_LIST_REQUEST_SUCCESSFUL,
} from "./action";
import {
  UPDATE_USER_PROFILE_STATUS_REQUEST,
  UPDATE_USER_PROFILE_STATUS_REQUEST_FAILURE,
  UPDATE_USER_PROFILE_STATUS_REQUEST_SUCCESS,
} from "../authentication/actions";

const initialState = {
  users: {
    isLoading: false,
    all: [],
  },
  profiles: {},
  updateUser: {
    isLoading: false,
  },
  invites: {
    isLoading: false,
    all: [],
    addInvite: {
      isLoading: false,
      invite: {},
    },
  },
  isAdminMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_MODE:
      return {
        ...state,
        isAdminMode: action.payload,
      };
    case USERS_LIST_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
        },
      };
    case USERS_LIST_REQUEST_SUCCESSFUL:
      let all = [];
      if (state.users.all && action.payload) {
        all = state.users.all.concat(action.payload);
        all = all.filter((item, index) => {
          return all.indexOf(item) === index;
        });
      } else {
        all = state.users.all;
      }

      return {
        ...state,
        users: {
          all,
          isLoading: false,
        },
      };
    case USERS_LIST_REQUEST_FAILURE:
      return {
        ...state,
        users: {
          all: state.users.all,
          isLoading: false,
        },
      };

    case USER_PROFILE_REQUEST:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.payload.username]: {
            isLoading: true,
          },
        },
      };
    case USER_PROFILE_REQUEST_SUCCESS:
      let temp = state.users.all.filter((u) => {
        return u.id === action.payload.user.id;
      });

      if (temp.length) {
        temp = state.users.all;
      } else {
        temp = [...state.users.all, action.payload.user];
      }
      return {
        ...state,
        users: {
          ...state.users,
          all: temp,
        },
        profiles: {
          ...state.profiles,
          [action.payload.username]: {
            isLoading: false,
            ...action.payload.profile,
          },
        },
      };
    case USER_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.payload.username]: {
            isLoading: false,
          },
        },
      };

    case UPDATE_USER_PROFILE_STATUS_REQUEST:
      return {
        ...state,
        updateUser: {
          isLoading: true,
        },
      };
    case UPDATE_USER_PROFILE_STATUS_REQUEST_SUCCESS:
    case UPDATE_USER_PROFILE_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        updateUser: {
          isLoading: false,
        },
      };

    case LIST_USER_INVITATIONS:
      return {
        ...state,
        invites: {
          ...state.invites,
          isLoading: true,
        },
      };
    case LIST_USER_INVITES_REQUEST_SUCCESS:
    case LIST_USER_INVITES_REQUEST_FAILURE:
      return {
        ...state,
        invites: {
          ...state.invites,
          isLoading: false,
          ...action.payload,
        },
      };

    case SEND_USER_INVITES:
      return {
        ...state,
        invites: {
          ...state.invites,
          addInvite: {
            isLoading: true,
          },
        },
      };
    case SEND_USER_INVITES_REQUEST_SUCCESS:
    case SEND_USER_INVITES_REQUEST_FAILURE:
      return {
        ...state,
        invites: {
          ...state.invites,
          addInvite: {
            isLoading: false,
            invite: action.payload,
          },
        },
      };

    case UPDATE_USER_INVITES:
      return {
        ...state,
        invites: {
          ...state.invites,
          updateInvite: {
            isLoading: true,
          },
        },
      };
    case UPDATE_USER_INVITES_REQUEST_SUCCESS:
    case UPDATE_USER_INVITES_REQUEST_FAILURE:
      return {
        ...state,
        invites: {
          ...state.invites,
          updateInvite: {
            isLoading: false,
          },
        },
      };
    default:
      return state;
  }
};
