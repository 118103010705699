import axios from "../global/axios";
import axiosApiInstance from "../global/axios";
import { error, success } from "@pnotify/core";
import DEMO from "../global/constant";
import { genericErrorHandler } from "../global/common";

export const SIMPLE_USER_REQUEST = "SIMPLE_USER_REQUEST";
// export const SIMPLE_USER_REQUEST_SUCCESSFUL = "SIMPLE_USER_REQUEST_SUCCESSFUL";
// export const SIMPLE_USER_REQUEST_FAILURE = "SIMPLE_USER_REQUEST_FAILURE";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_REQUEST_SUCCESS = "USER_PROFILE_REQUEST_SUCCESS";
export const USER_PROFILE_REQUEST_FAILURE = "USER_PROFILE_REQUEST_FAILURE";

export const ADMIN_MODE = "ADMIN_MODE";

export function setAdminMode(payload) {
  return {
    type: ADMIN_MODE,
    payload,
  };
}

export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_REQUEST_SUCCESSFUL = "USERS_LIST_REQUEST_SUCCESSFUL";
export const USERS_LIST_REQUEST_FAILURE = "USERS_LIST_REQUEST_FAILURE";

const requestListUsers = (payload) => ({
  type: USERS_LIST_REQUEST,
  payload,
});

const requestListUsersSuccess = (payload) => ({
  type: USERS_LIST_REQUEST_SUCCESSFUL,
  payload,
});

const requestListUsersFailure = (payload) => ({
  type: USERS_LIST_REQUEST_FAILURE,
  payload,
});

export const fetchUsers = (payload) => async (dispatch, getState) => {
  const { admin } = getState();

  const err = () => {
    dispatch(requestListUsersFailure(payload));
  };

  if (!admin.users.isLoading) {
    try {
      dispatch(requestListUsers(payload));
      const response = await axios.get(`${DEMO.BASE_URL}/accounts/users/`);

      if (response) {
        if (response.status === 200) {
          dispatch(requestListUsersSuccess(response.data));
        }
      } else {
        err();
      }
    } catch (e) {
      if (e.response) {
        err();
        if (e.response.status === 401) {
          error({
            title: "Authorization Error",
            text: DEMO.UNAUTHORISED_ACCESS_ERROR,
          });
        }
      } else {
        genericErrorHandler(dispatch, requestListUsersFailure, e);
      }
    }
  }
};

const requestUserProfile = (payload) => ({
  type: USER_PROFILE_REQUEST,
  payload,
});

const requestUserProfileSuccess = (payload) => ({
  type: USER_PROFILE_REQUEST_SUCCESS,
  payload,
});

const requestUserProfileFailure = (payload) => ({
  type: USER_PROFILE_REQUEST_FAILURE,
  payload,
});

export const getProfile = (username) => async (dispatch, getState) => {
  const {
    admin: { profiles },
  } = getState();
  const isLoading = profiles[username] ? !!profiles[username].isLoading : false;
  if (!isLoading) {
    try {
      dispatch(requestUserProfile({ username }));
      const response = await axiosApiInstance.get(
        `${DEMO.BASE_URL}/accounts/profile/${username}/search/`
      );

      if (response && response.status === 200) {
        dispatch(requestUserProfileSuccess({ ...response.data, username }));
      } else {
        dispatch(requestUserProfileFailure({ username }));
      }
    } catch (e) {
      dispatch(requestUserProfileFailure({ username }));
    }
  }
};

export const LIST_USER_INVITATIONS = "LIST_USER_INVITATIONS";
export const LIST_USER_INVITES_REQUEST_SUCCESS = "LIST_USER_INVITES_REQUEST_SUCCESS";
export const LIST_USER_INVITES_REQUEST_FAILURE = "LIST_USER_INVITES_REQUEST_FAILURE";

const requestListInvites = (payload) => ({
  type: LIST_USER_INVITATIONS,
  payload,
});

const requestListInvitesSuccess = (payload) => ({
  type: LIST_USER_INVITES_REQUEST_SUCCESS,
  payload,
});

const requestListInvitesFailure = (payload) => ({
  type: LIST_USER_INVITES_REQUEST_FAILURE,
  payload,
});

export const fetchUserInvites = (email, isActive) => async (dispatch, getState) => {
  const {
    admin: { invites: currentInvites },
  } = getState();

  if (!currentInvites.isLoading) {
    let url = `${DEMO.BASE_URL}/accounts/invites/`;

    if (email) {
      let invite = null;
      // check that the email we are using is not already in redux
      invite = currentInvites.all.filter((invite) => invite.email === email);
      if (invite) {
        // return if found
        return;
      } else {
        // else look for that email specifically
        url = `${DEMO.BASE_URL}/accounts/invites?email=${email}`;
      }
    }

    try {
      dispatch(requestListInvites({ email, isActive }));
      debugger;
      const response = await axiosApiInstance.get(url);
      if (response && response.status === 200) {
        if (!email) {
          dispatch(requestListInvitesSuccess({ all: response.data }));
        } else {
          dispatch(requestListInvitesSuccess({ all: [...currentInvites.all, ...response.data] }));
        }
      } else {
        dispatch(requestListInvitesFailure({ all: currentInvites.all }));
        error({
          title: "Error",
          text: "There was an error listing user invites",
        });
      }
    } catch (e) {
      dispatch(requestListInvitesFailure({ all: currentInvites.all }));
      error({
        title: "Error",
        text: "There was an error listing user invites",
      });
    }
  }
};

export const SEND_USER_INVITES = "SEND_USER_INVITES";
export const SEND_USER_INVITES_REQUEST_SUCCESS = "SEND_USER_INVITES_REQUEST_SUCCESS";
export const SEND_USER_INVITES_REQUEST_FAILURE = "SEND_USER_INVITES_REQUEST_FAILURE";

const requestSendInvites = (payload) => ({
  type: SEND_USER_INVITES,
  payload,
});

const requestSendInvitesSuccess = (payload) => ({
  type: SEND_USER_INVITES_REQUEST_SUCCESS,
  payload,
});

const requestSendInvitesFailure = (payload) => ({
  type: SEND_USER_INVITES_REQUEST_FAILURE,
  payload,
});

export const sendAddUserInvites = (email) => async (dispatch, getState) => {
  const {
    admin: {
      invites: { addInvite },
    },
  } = getState();

  if (!addInvite.isLoading) {
    try {
      dispatch(requestSendInvites({ email }));
      const response = await axiosApiInstance.post(`${DEMO.BASE_URL}/accounts/invites/`, { email });
      if (response) {
        if (response.status === 201) {
          dispatch(requestSendInvitesSuccess(response.data));
          success({
            title: "Message",
            text: "Invite sent successfully",
          });
          dispatch(fetchUserInvites());
        } else if (response.status === 400) {
          dispatch(requestSendInvitesFailure(response.data));
          error({
            title: "Error",
            text: response.data["message"],
          });
        }
      }
    } catch (e) {
      if (e.response && e.response.data) {
        dispatch(requestSendInvitesFailure(e.response.data));
        error({
          title: "Error",
          text: e.response.data["message"],
        });
      } else {
        genericErrorHandler(dispatch, requestSendInvitesFailure, e);
      }
    }
  }
};

export const UPDATE_USER_INVITES = "UPDATE_USER_INVITES";
export const UPDATE_USER_INVITES_REQUEST_SUCCESS = "UPDATE_USER_INVITES_REQUEST_SUCCESS";
export const UPDATE_USER_INVITES_REQUEST_FAILURE = "UPDATE_USER_INVITES_REQUEST_FAILURE";

const requestUpdateInvite = (payload) => ({
  type: UPDATE_USER_INVITES,
  payload,
});

const requestUpdateInviteSuccess = (payload) => ({
  type: UPDATE_USER_INVITES_REQUEST_SUCCESS,
  payload,
});

const requestUpdateInviteFailure = (payload) => ({
  type: UPDATE_USER_INVITES_REQUEST_FAILURE,
  payload,
});

export const updateUserInvite = (payload) => async (dispatch, getState) => {
  const {
    admin: {
      invites: { all },
    },
  } = getState();
  const invite = all.filter((item) => item.id === payload.id);
  if (invite && !invite[0].isLoading) {
    try {
      dispatch(requestUpdateInvite(payload));
      const response = await axiosApiInstance.post(
        `${DEMO.BASE_URL}/accounts/invites/${invite[0].id}/update_status/`,
        { action: payload.action }
      );
      if (response) {
        if (response.status === 200) {
          dispatch(requestUpdateInviteSuccess(response.data));
          dispatch(fetchUserInvites());
        } else if (response.status === 400 || response.status === 404) {
          dispatch(requestUpdateInviteFailure(payload));
          error({
            title: "Error",
            text: `Error updating the invite. ${response.data["message"]}`,
          });
        } else {
          genericErrorHandler(dispatch, requestUpdateInviteFailure, new Error());
        }
      }
    } catch (e) {
      if (e.response && e.response.data) {
        dispatch(requestUpdateInviteFailure(e.response.data));
        error({
          title: "Error",
          text: `Error updating the invite. ${e.response.data["message"]}`,
        });
      } else {
        genericErrorHandler(dispatch, requestUpdateInviteFailure, e);
      }
    }
  }
};
