import { combineReducers } from "redux";
import authReducer from "./authentication/reducer";
import adminReducer from "./admin/reducer";
import globalReducer from "./global/reducer";
import strapiReducer from "./strapi/reducers";
import ticketsReducer from "./tickets/reducers";
import jobsReducer from "./jobs/reducers";
import sessionReducer from "./session/reducers";
import calendarReducer from "../Pages/calendar/reducers";
import invitationReducers from "../../src/App/layout/AdminLayout/NavBar/NavRight/ChatList/reducers";
import { connectRouter } from "connected-react-router";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    common: globalReducer,
    auth: authReducer,
    admin: adminReducer,
    session: sessionReducer,
    strapi: strapiReducer,
    tickets: ticketsReducer,
    jobs: jobsReducer,
    calendar: calendarReducer,
    invitations: invitationReducers,
  });

export default createRootReducer;
