import {
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_REQUEST_FAILURE,
  FETCH_INVOICE_REQUEST_SUCCESS,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_REQUEST_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST_SUCCESS,
} from "./action";
import { handlePayload } from "../jobs/reducers";

const initialState = {
  notifications: {
    isLoading: false,
    data: {},
    requestFailedAt: null,
    requestSuccessfulAt: null,
  },
  invoices: {
    isLoading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notifications: {
          isLoading: true,
          data: {},
        },
      };
    case FETCH_NOTIFICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        notifications: {
          isLoading: false,
          data: action.payload,
          requestSuccessfulAt: new Date(),
          requestFailedAt: null,
        },
      };
    case FETCH_NOTIFICATIONS_REQUEST_FAILURE:
      return {
        ...state,
        notifications: {
          isLoading: false,
          data: { errors: action.payload },
          requestSuccessfulAt: null,
          requestFailedAt: new Date(),
        },
      };

    case FETCH_INVOICE_REQUEST:
      return handlePayload(state, action.payload, "notifications", {
        isLoading: true,
      });
    case FETCH_INVOICE_REQUEST_SUCCESS:
      return handlePayload(state, action.payload, "notifications", {
        isLoading: false,
        ...action.payload.data,
        error: null,
      });
    case FETCH_INVOICE_REQUEST_FAILURE:
      return handlePayload(state, action.payload, "notifications", {
        isLoading: true,
        error: action.payload.err,
        ...action.payload.data,
      });

    default:
      return state;
  }
};
