export const TICKET_TYPE_ACCOUNT_APPROVAL = 1;
export const TICKET_TYPE_JOB_APPLICATION = 2;
export const TICKET_TYPE_SERVICES_REQUEST = 3;
export const TICKET_TYPE_ENQUIRY = 4;
export const TICKET_TYPE_CREDIT_REQUEST = 5;
export const TICKET_TYPE_JOB_ASSIGNMENT = 6;
export const TICKET_TYPE_INVITATION = 7;

export const TICKET_STATUS_PENDING = 0;
export const TICKET_STATUS_APPROVED = 1;
export const TICKET_STATUS_REJECTED = 2;
export const TICKET_STATUS_CANCELLED = 3;

export const JOB_STATUS_PUBLISHED = 1;
export const JOB_STATUS_UNPUBLISHED = 2;
export const JOB_STATUS_REMOVED = 3;

export const STAFFING_STATUS_PENDING_REVIEW = 0;
export const STAFFING_STATUS_STAFFED = 1;
export const STAFFING_STATUS_REJECTED = 2;

const DEMO = {
  BLANK_LINK: "#!",
  APP_NAME: "HEALTH CONNECT",
  GENERIC_ERROR:
    "It's not you , its us. We are aware of this and will address the issue. Please try again later",
  UNAUTHORISED_ACCESS_ERROR: "You are not authorised to carry out this operation",
  // BASE_URL: "http://localhost:8000/api/v1",
  // BASE_URL: process.env.REACT_APP_BASE_API_URL || "https://api.healthconnect.co.ke/api/v1",
  BASE_URL: process.env.REACT_APP_BASE_API_URL,
  STRAPI_URL: process.env.REACT_APP_STRAPI_BASE_URL,
  BASE_BACKEND_URL: process.env.REACT_APP_BASE_API,
  ACCESS_TOKEN_NAME: "nnfnfjnkmfnks",
  REFRESH_TOKEN_NAME: "jfjfjfkls",
  USER_TYPES: ["worker", "institution", "individual"],
  TICKET_STATUSES: {
    [TICKET_STATUS_PENDING]: "Pending Review",
    [TICKET_STATUS_APPROVED]: "Approved",
    [TICKET_STATUS_REJECTED]: "Rejected",
    [TICKET_STATUS_CANCELLED]: "Cancelled",
  },
  TICKET_TYPES: {
    [TICKET_TYPE_ACCOUNT_APPROVAL]: "Account Approval",
    [TICKET_TYPE_JOB_APPLICATION]: "Job Application",
    [TICKET_TYPE_SERVICES_REQUEST]: "Request For Services",
    [TICKET_TYPE_ENQUIRY]: "Enquiry",
    [TICKET_TYPE_CREDIT_REQUEST]: "Credit Request",
    [TICKET_TYPE_JOB_ASSIGNMENT]: "Job Assignment",
    [TICKET_TYPE_INVITATION]: "Invitation",
  },
  JOB_STATUSES: {
    [JOB_STATUS_PUBLISHED]: "Job published",
    [JOB_STATUS_UNPUBLISHED]: "Job Unpublished",
    [JOB_STATUS_REMOVED]: "Job Removed",
  },
  STAFFING_STATUSES: {
    [STAFFING_STATUS_PENDING_REVIEW]: "Not Confirmed",
    [STAFFING_STATUS_STAFFED]: "Accepted",
    [STAFFING_STATUS_REJECTED]: "Rejected",
  },
};

export const USER_TYPE_MEDICAL_WORKER = 1;
export const USER_TYPE_APP_USER = 2;
export const USER_TYPE_INSTITUTION = 3;
export const USER_TYPE_INSTITUTION_AGENT = 4;

export const USER_TYPES_MAP = {
  worker: USER_TYPE_MEDICAL_WORKER,
  institution: USER_TYPE_INSTITUTION,
  individual: USER_TYPE_APP_USER,
  agent: USER_TYPE_INSTITUTION_AGENT,
};

export const genderOptions = [
  { value: 3, label: "Select One", name: "gender" },
  { value: 1, label: "Male", name: "gender" },
  { value: 2, label: "Female", name: "gender" },
];

export const specializationOptions = [
  { value: 0, label: "Select One" },
  { value: 1, label: "Doctor" },
  { value: 2, label: "Nurse" },
  { value: 3, label: "Pharmacist" },
  { value: 4, label: "Clinical Officer" },
  { value: 5, label: "Physiotherapist" },
  { value: 6, label: "Lab Technician" },
  { value: 7, label: "Other" },
];

export const servicesOptions = [
  { value: 0, label: "Medical Reviews", name: "services" },
  { value: 1, label: "Skilled Nursing", name: "services" },
  { value: 2, label: "Laboratory Services", name: "services" },
  { value: 3, label: "Therapy ", name: "services" },
  { value: 4, label: "Cancer Care", name: "services" },
  { value: 5, label: "Medical Equipment", name: "services" },
];

export const servicesForSelf = "self";
export const servicesForOther = "other";
export const servicesRequestedFor = [
  { value: servicesForSelf, label: "Self" },
  { value: servicesForOther, label: "Other" },
];

export default DEMO;
