import axiosApiInstance from "../global/axios";
import DEMO from "../global/constant";
import { genericErrorHandler } from "../global/common";
import { error } from "@pnotify/core";

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_REQUEST_SUCCESS = "FETCH_NOTIFICATIONS_REQUEST_SUCCESS";
export const FETCH_NOTIFICATIONS_REQUEST_FAILURE = "FETCH_NOTIFICATIONS_REQUEST_FAILURE";

function getNotifications(payload) {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
    payload,
  };
}

function getNotificationsSuccess(payload) {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST_SUCCESS,
    payload,
  };
}

function getNotificationsFailure(payload) {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST_FAILURE,
    payload,
  };
}

export const fetchNotifications = (payload) => async (dispatch, getState) => {
  const {
    session: { notifications },
  } = getState();
  if (!notifications.isLoading) {
    dispatch(getNotifications(payload));
    try {
      const response = await axiosApiInstance.get(
        // `${DEMO.BASE_URL}/accounts/profile/notifications/api/unread_list/`,
        `${DEMO.BASE_URL}/accounts/notifications/unread/`
      );

      if (response && response.status === 200) {
        dispatch(getNotificationsSuccess(response.data));
      } else {
        dispatch(getNotificationsFailure(payload));
      }
    } catch (e) {
      if (e.response) {
        dispatch(getNotificationsFailure(e.response.data));
      } else {
        genericErrorHandler(dispatch, getNotificationsFailure, e);
      }
    }
  }
};

export const FETCH_INVOICE_REQUEST = "FETCH_INVOICE_REQUEST";
export const FETCH_INVOICE_REQUEST_SUCCESS = "FETCH_INVOICE_REQUEST_SUCCESS";
export const FETCH_INVOICE_REQUEST_FAILURE = "FETCH_INVOICE_REQUEST_FAILURE";

function getInvoices(payload) {
  return {
    type: FETCH_INVOICE_REQUEST,
    payload,
  };
}

function getInvoicesSuccess(payload) {
  return {
    type: FETCH_INVOICE_REQUEST_SUCCESS,
    payload,
  };
}

function getInvoicesFailure(payload) {
  return {
    type: FETCH_INVOICE_REQUEST_FAILURE,
    payload,
  };
}

export const fetchInvoices = (payload) => async (dispatch, getState) => {
  const {
    session: {
      invoices: { data },
    },
  } = getState();
  const { invoiceId } = payload;
  const loading = invoiceId && data[invoiceId] ? data[invoiceId].isLoading : false;

  if (!loading) {
    dispatch(getInvoices({ dataId: invoiceId || "all" }));
    let url = invoiceId
      ? `${DEMO.BASE_URL}/accounts/invoice/${invoiceId}/`
      : `${DEMO.BASE_URL}/accounts/invoice/`;
    try {
      const response = await axiosApiInstance.get(url);

      if (response && response.status === 200) {
        if (Array.isArray(response.data)) {
          dispatch(getInvoicesSuccess({ dataId: "all", data: {} }));
          response.data.forEach((item) => {
            dispatch(getInvoicesSuccess({ dataId: item.id, data: item }));
          });
        } else {
          dispatch(
            getInvoicesSuccess({ dataId: response.data.id, data: response.data, err: null })
          );
        }
      } else {
        dispatch(getInvoicesFailure({ dataId: invoiceId || "all", data: {}, err: response.data }));
      }
    } catch (e) {
      if (e.response) {
        dispatch(
          getInvoicesFailure({ dataId: invoiceId || "all", data: {}, err: e.response.data })
        );
      } else {
        dispatch(getInvoicesFailure({ dataId: invoiceId || "all", data: {}, err: e }));
      }
      error({
        title: "Error",
        text: `There was an error retrieving your invoice${invoiceId ? "" : "s"}`,
      });
    }
  }
};
