export const CREATE_USER_INVITES = "CREATE_USER_INVITES";
export const LIST_USER_INVITES = "LIST_USER_INVITES";
export const RESET_FORM = "RESET_FORM";

export const createInvite = (payload) => ({
  type: CREATE_USER_INVITES,
  payload,
  url: "accounts/invites",
  method: "POST",
});

export const listInvites = (userId) => ({
  type: LIST_USER_INVITES,
  url: "accounts/invites",
  method: "GET",
  userId,
});

export const resetForm = (mode) => ({
  type: RESET_FORM,
  mode,
});
