// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
import axios from "axios";
import { cookies } from "./common";
import DEMO from "./constant";
import { info } from "@pnotify/core";

const axiosApiInstance = axios.create();

export const logout = () => {
  cookies.remove(DEMO.ACCESS_TOKEN_NAME);
  cookies.remove(DEMO.REFRESH_TOKEN_NAME);
};

export const redirectToLogin = (showNotification, message) => {
  logout();
  setTimeout(
    () => {
      window.location.href = "/auth/sign_in";
    },
    showNotification ? 2000 : 0
  );

  if (showNotification) {
    info({
      title: "Message",
      text: message || "Please login to proceed",
    });
  }
};

const refreshAccessToken = async () => {
  try {
    if (cookies.get(DEMO.REFRESH_TOKEN_NAME)) {
      const response = await axios.post(`${DEMO.BASE_URL}/accounts/token/refresh/`, {
        refresh: cookies.get(DEMO.REFRESH_TOKEN_NAME),
      });

      if (response.status === 200 && response.data()) {
        cookies.set(DEMO.ACCESS_TOKEN_NAME, response.data.access, { path: "/" });
        return response.data.access;
      }
    }
  } catch (e) {
    redirectToLogin();
  }
};

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (!error.response) {
      //  if no response is received from the server
      redirectToLogin(
        true,
        `Something went wrong communicating with one of our services. Please try again later`
      );
      return;
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      if (!access_token) {
        return;
      }
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
    } else {
      if (error.response.status === 401) {
        redirectToLogin(true);
        return error.response;
      }
    }
    return error.response;
  }
);

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    if (!cookies.get(DEMO.ACCESS_TOKEN_NAME)) {
      redirectToLogin();
    } else {
      config.headers = {
        Authorization: `Bearer ${cookies.get(DEMO.ACCESS_TOKEN_NAME)}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosApiInstance;
