import { CREATE_USER_INVITES, LIST_USER_INVITES, RESET_FORM } from "./actions";
import { FINISHED, REQUESTED } from "../../../../../../utils";

const initialState = {
  invitations: {},
  create: {},
  fetch: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_FORM:
      return {
        ...state,
        [action.mode]: {},
      };
    case CREATE_USER_INVITES:
      if (action.state === REQUESTED) {
        return {
          ...state,
          create: {
            ...state.create,
            isLoading: true,
            message: {
              text: "Sending invite",
              variant: "success",
            },
          },
        };
      }

      if (action.state === FINISHED && action.response) {
        const data = action.response.data;
        const code = action.response.status;
        return {
          ...state,
          create: {
            isLoading: false,
            message: {
              text: data.message
                ? data.message
                : code === 201
                ? "invite sent successfully"
                : "error sending invitation",
              variant: code === 201 ? "success" : "warning",
            },
          },
        };
      }
      return {
        ...state,
        create: {
          isLoading: false,
          message: {
            text: "error sending invitation",
            variant: "warning",
          },
        },
      };
    case LIST_USER_INVITES:
      if (action.state === REQUESTED) {
        return {
          ...state,
          fetch: {
            ...state.create,
            isLoading: true,
          },
        };
      }

      if (action.state === FINISHED && action.response && action.response.status === 200) {
        return {
          ...state,
          fetch: {
            isLoading: false,
          },
          invitations: {
            [action.userId]: action.response.data,
          },
        };
      }

      return {
        ...state,
        fetch: {
          isLoading: false,
          message: {
            text: "error displaying invitations",
            variant: "warning",
          },
        },
      };

    default:
      return state;
  }
};
