import axios from "axios";
import DEMO from "../global/constant";
import { error, success } from "@pnotify/core";

export const RETRIEVE_ITEM = "RETRIEVE_ITEM";
export const RETRIEVE_ITEM_SUCCESS = "RETRIEVE_ITEM_SUCCESS";
export const RETRIEVE_ITEM_FAILURE = "RETRIEVE_ITEM_FAILURE";

function getStrapiPost(payload) {
  return {
    type: RETRIEVE_ITEM,
    payload,
  };
}

function getStrapiPostSuccess(payload) {
  return {
    type: RETRIEVE_ITEM_SUCCESS,
    payload,
  };
}

function getStrapiPostFailure(payload) {
  return {
    type: RETRIEVE_ITEM_FAILURE,
    payload,
  };
}

export const fetchStrapiPost = (payload) => async (dispatch, getState) => {
  const { strapi: data } = getState();
  const { dataType, dataId, companyId } = payload;
  let isLoading = false;

  if (data[dataType] && dataId && data[dataType][dataId]) {
    isLoading = data[dataType][dataId].isLoading;
  } else if (data[dataType]) {
    isLoading = data[dataType].isLoading;
  }

  if (!isLoading) {
    dispatch(getStrapiPost(payload));

    let url = `${DEMO.STRAPI_URL}/${dataType}`;
    if (dataId) {
      url = `${url}/${dataId}`;
    }
    if (companyId) {
      url = `${url}?companyId_eq=${companyId}`;
    }

    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (dataId) {
            dispatch(getStrapiPostSuccess({ dataType, dataId, data: res.data }));
          } else {
            let items = {};
            if (Array.isArray(res.data)) {
              res.data.forEach((item) => {
                items = { ...items, [item.id]: item };
              });
            }
            dispatch(getStrapiPostSuccess({ dataType, dataId, data: items }));
          }
        }
      })
      .catch((err) => {
        dispatch(getStrapiPostFailure({ dataType, dataId, data: {}, err }));
      });
  }
};

export const ADD_ITEM = "ADD_ITEM";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILURE = "ADD_ITEM_FAILURE";

function addStrapiPost(payload) {
  return {
    type: ADD_ITEM,
    payload,
  };
}

function addStrapiPostSuccess(payload) {
  return {
    type: ADD_ITEM_SUCCESS,
    payload,
  };
}

function addStrapiPostFailure(payload) {
  return {
    type: ADD_ITEM_FAILURE,
    payload,
  };
}

export const doAddStrapiPost = (payload) => async (dispatch, getState) => {
  const { strapi: addData } = getState();
  const { dataType, companyId, data } = payload;
  let isLoading = false;
  if (addData[dataType]) {
    isLoading = addData[dataType].isLoading;
  }

  if (!isLoading && companyId) {
    dispatch(addStrapiPost(payload));
    const url = `${DEMO.STRAPI_URL}/${dataType}`;
    await axios
      .post(url, { ...data, companyId })
      .then((res) => {
        dispatch(addStrapiPostSuccess({ dataType, [res.data.id]: res.data, data: res.data }));
        dispatch(getStrapiPostSuccess({ dataType, dataId: res.data.id, data: res.data }));
        success({
          title: "Message",
          text: "The job posting has been made successfully",
        });
      })
      .catch((err) => {
        dispatch(
          addStrapiPostFailure({
            dataType,
            err: err.response ? err.response.data : err,
            data: {},
          })
        );
        error({
          title: "Error",
          text: "The job posting has not been successful added",
        });
      });
  }
};

export const EDIT_ITEM = "EDIT_ITEM";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAILURE = "EDIT_ITEM_FAILURE";

function editStrapiPost(payload) {
  return {
    type: EDIT_ITEM,
    payload,
  };
}

function editStrapiPostSuccess(payload) {
  return {
    type: EDIT_ITEM_SUCCESS,
    payload,
  };
}

function editStrapiPostFailure(payload) {
  return {
    type: EDIT_ITEM_FAILURE,
    payload,
  };
}

export const doEditStrapiPost = (payload) => async (dispatch, getState) => {
  const { strapi: editData } = getState();
  const { dataType, companyId, data, dataId } = payload;
  let isLoading = false;

  if (editData[dataType]) {
    isLoading = editData[dataType].isLoading;
  }

  if (!isLoading && companyId) {
    dispatch(editStrapiPost(payload));
    const url = `${DEMO.STRAPI_URL}/${dataType}/${dataId}`;
    await axios
      .put(url, { ...data, companyId })
      .then((res) => {
        dispatch(editStrapiPostSuccess({ dataType, [res.data.id]: res.data, data: res.data }));
        dispatch(getStrapiPostSuccess({ dataType, dataId: res.data.id, data: res.data }));
        success({
          title: "Message",
          text: "The job posting has been updated successfully",
        });
      })
      .catch((err) => {
        dispatch(
          editStrapiPostFailure({
            dataType,
            err: err.response ? err.response.data : err,
            data: {},
          })
        );
        error({
          title: "Error",
          text: "The job posting has not been successful edited",
        });
      });
  }
};

export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";

function deleteStrapiPost(payload) {
  return {
    type: DELETE_ITEM,
    payload,
  };
}

function deleteStrapiPostSuccess(payload) {
  return {
    type: DELETE_ITEM_SUCCESS,
    payload,
  };
}

function deleteStrapiPostFailure(payload) {
  return {
    type: DELETE_ITEM_FAILURE,
    payload,
  };
}

export const doDeleteStrapiPost = (payload) => async (dispatch, getState) => {
  const { strapi: removeData } = getState();

  const { dataType, dataId } = payload;
  let isLoading = false;

  if (removeData[dataType] && dataId && removeData[dataType][dataId]) {
    isLoading = removeData[dataType][dataId].isLoading;
  } else if (removeData[dataType]) {
    isLoading = removeData[dataType].isLoading;
  }

  if (!isLoading) {
    dispatch(deleteStrapiPost(payload));
    const url = `${DEMO.STRAPI_URL}/${dataType}/${dataId}`;

    await axios
      .delete(url)
      .then((res) => {
        dispatch(deleteStrapiPostSuccess({ dataType, dataId, data: res.data }));
        success({
          title: "Success",
          text: "Job Post deleted successfully",
        });
      })
      .catch((err) => {
        dispatch(deleteStrapiPostFailure({ dataType, dataId, data: {}, err }));
        error({
          title: "Error",
          text: "Job Post was not deleted successfully",
        });
      });
  }
};
