import axiosApiInstance from "./store/global/axios";
import DEMO from "./store/global/constant";
import { error } from "@pnotify/core";

export const REQUESTED = Symbol("REQUEST INITIATED");
export const FINISHED = Symbol("REQUEST FINISHED");

const handleAction = (action) => async (dispatch) => {
  // if action  goes to the backend handle it here
  if (action.url && action.method) {
    dispatch({ ...action, state: REQUESTED });
    try {
      const response = await axiosApiInstance({
        method: action.method,
        url: `${DEMO.BASE_URL}/${action.url}/`,
        data: action.payload || {},
      });
      if (response) {
        dispatch({ ...action, response, state: FINISHED });
      }
    } catch (e) {
      if (e.response) {
        dispatch({ ...action, response: e.response, state: FINISHED });
        if (e.response.status === 401 || e.response.status === 403) {
          error({
            title: "Authorization Error",
            text: DEMO.UNAUTHORISED_ACCESS_ERROR,
          });
        }
      } else {
        dispatch({ ...action, response: null, error: e, state: FINISHED });
      }
    }
  } else {
    // otherwise dispatch it to the store
    dispatch(action);
  }
};

export function object_equals(x, y) {
  let p;
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!object_equals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export default handleAction;
