import DEMO from "../global/constant";
import { error, success } from "@pnotify/core";
import axiosApiInstance from "../global/axios";
import { CustomError } from "../tickets/actions";

export const RETRIEVE_JOB_ITEM = "RETRIEVE_JOB_ITEM";
export const RETRIEVE_JOB_ITEM_SUCCESS = "RETRIEVE_JOB_ITEM_SUCCESS";
export const RETRIEVE_JOB_ITEM_FAILURE = "RETRIEVE_JOB_ITEM_FAILURE";

function getJobPost(payload) {
  return {
    type: RETRIEVE_JOB_ITEM,
    payload,
  };
}

function getJobPostSuccess(payload) {
  return {
    type: RETRIEVE_JOB_ITEM_SUCCESS,
    payload,
  };
}

function getJobPostFailure(payload) {
  return {
    type: RETRIEVE_JOB_ITEM_FAILURE,
    payload,
  };
}

export const doGetJobPost = (payload) => async (dispatch, getState) => {
  const {
    jobs: { data },
  } = getState();
  const { dataId } = payload;

  const isLoading = data[dataId] ? data[dataId].isLoading : data.isLoading;

  if (!isLoading) {
    dispatch(getJobPost(payload));
    const url = dataId ? `${DEMO.BASE_URL}/jobs/${dataId}/` : `${DEMO.BASE_URL}/jobs/`;
    await axiosApiInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.id) {
            dispatch(getJobPostSuccess({ dataId: res.data.id, data: res.data }));
          } else {
            res.data.forEach((item) => {
              dispatch(getJobPostSuccess({ dataId: item.id, data: item }));
            });
          }
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch(
            getJobPostFailure({
              data: {},
              err: err.response.data,
            })
          );
        } else {
          dispatch(
            getJobPostFailure({
              data: {},
              err,
            })
          );
        }
      });
  }
};

export const ADD_JOB_ITEM = "ADD_JOB_ITEM";
export const ADD__JOB_ITEM_SUCCESS = "ADD__JOB_ITEM_SUCCESS";
export const ADD_JOB_ITEM_FAILURE = "ADD_JOB_ITEM_FAILURE";
export const RESET_ADD_NEW_JOB = "RESET_ADD_NEW_JOB";

function addJobPost(payload) {
  return {
    type: ADD_JOB_ITEM,
    payload,
  };
}

function addJobPostSuccess(payload) {
  return {
    type: ADD__JOB_ITEM_SUCCESS,
    payload,
  };
}

function addJobPostFailure(payload) {
  return {
    type: ADD_JOB_ITEM_FAILURE,
    payload,
  };
}

function resetAddJobPost() {
  return {
    type: RESET_ADD_NEW_JOB,
    payload: {},
  };
}

export const doResetAddJobPost = () => async (dispatch, getState) => {
  const {
    jobs: { addData },
  } = getState();
  if (addData.id) {
    dispatch(resetAddJobPost());
  }
};

export const doAddJobPost = (payload) => async (dispatch, getState) => {
  const { jobs: addData } = getState();

  if (!addData.isLoading) {
    dispatch(addJobPost(payload));
    const url = `${DEMO.BASE_URL}/jobs/`;

    await axiosApiInstance
      .post(url, payload)
      .then((res) => {
        if (res.status === 201) {
          dispatch(addJobPostSuccess({ data: res.data }));
          dispatch(getJobPostSuccess({ dataId: res.data.id, data: res.data }));
          success({
            title: "Message",
            text: "The job posting has been made successfully",
          });
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch(
            addJobPostFailure({
              data: {},
              err: err.response.data,
            })
          );
        } else {
          dispatch(
            addJobPostFailure({
              data: {},
              err,
            })
          );
        }
      });
  }
};

export const EDIT_JOB_ITEM = "EDIT_JOB_ITEM";
export const EDIT_JOB_ITEM_SUCCESS = "EDIT_JOB_ITEM_SUCCESS";
export const EDIT_JOB_ITEM_FAILURE = "EDIT_JOB_ITEM_FAILURE";

function editJobPost(payload) {
  return {
    type: EDIT_JOB_ITEM,
    payload,
  };
}

function editJobPostSuccess(payload) {
  return {
    type: EDIT_JOB_ITEM_SUCCESS,
    payload,
  };
}

function editJobPostFailure(payload) {
  return {
    type: EDIT_JOB_ITEM_FAILURE,
    payload,
  };
}

export const doEditJobPost = (payload) => async (dispatch, getState) => {
  const {
    jobs: { data, editData },
  } = getState();
  const { dataId } = payload;

  const isLoading = data[dataId] ? data[dataId].isLoading : editData.isLoading;
  if (!isLoading && dataId) {
    dispatch(editJobPost(payload));
    const url = `${DEMO.BASE_URL}/jobs/${dataId}/`;
    await axiosApiInstance
      .patch(url, payload.payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(editJobPostSuccess({ dataId, data: res.data }));
          dispatch(getJobPostSuccess({ dataId, data: res.data }));
          success({
            title: "Message",
            text: "The job posting has been updated successfully",
          });
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch(
            editJobPostFailure({
              data: {},
              err: err.response.data,
            })
          );
        } else {
          dispatch(
            editJobPostFailure({
              data: {},
              err,
            })
          );
        }

        error({
          title: "Error",
          text: "The job posting has not been updated successfully",
        });
      });
  }
};

// export const DELETE_JOB_ITEM = "DELETE_JOB_ITEM";
// export const DELETE_JOB_ITEM_SUCCESS = "DELETE_JOB_ITEM_SUCCESS";
// export const DELETE_JOB_ITEM_FAILURE = "DELETE_JOB_ITEM_FAILURE";

// function deleteJobPost(payload) {
//   return {
//     type: DELETE_JOB_ITEM,
//     payload,
//   };
// }
//
// function deleteJobPostSuccess(payload) {
//   return {
//     type: DELETE_JOB_ITEM_SUCCESS,
//     payload,
//   };
// }
//
// function deleteJobPostFailure(payload) {
//   return {
//     type: DELETE_JOB_ITEM_FAILURE,
//     payload,
//   };
// }

export const doDeleteJobPost = (payload) => async (dispatch, getState) => {};

export const RETRIEVE_JOB_STAFFING = "RETRIEVE_JOB_STAFFING";
export const RETRIEVE_JOB_STAFFING_SUCCESS = "RETRIEVE_JOB_STAFFING_SUCCESS";
export const RETRIEVE_JOB_STAFFING_FAILURE = "RETRIEVE_JOB_STAFFING_FAILURE";

function getJobStaffingPost(payload) {
  return {
    type: RETRIEVE_JOB_STAFFING,
    payload,
  };
}

function getJobStaffingSuccess(payload) {
  return {
    type: RETRIEVE_JOB_STAFFING_SUCCESS,
    payload,
  };
}

function getJobStaffingFailure(payload) {
  return {
    type: RETRIEVE_JOB_STAFFING_FAILURE,
    payload,
  };
}

export const doGetJobStaffing = (payload) => async (dispatch, getState) => {
  const {
    jobs: { staffing: data },
  } = getState();
  const { dataId } = payload;

  const isLoading = dataId && data[dataId] ? data[dataId].isLoading : false;

  if (!isLoading) {
    dispatch(getJobStaffingPost(payload));
    const url = dataId
      ? `${DEMO.BASE_URL}/jobs/staffing/${dataId}/`
      : `${DEMO.BASE_URL}/jobs/staffing/`;
    await axiosApiInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.id) {
            dispatch(getJobStaffingSuccess({ dataId: res.data.id, data: res.data }));
          } else {
            res.data.forEach((item) => {
              dispatch(getJobStaffingSuccess({ dataId: item.id, data: item }));
            });
          }
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch(
            getJobStaffingFailure({
              data: {},
              err: err.response.data,
            })
          );
        } else {
          dispatch(
            getJobStaffingFailure({
              data: {},
              err,
            })
          );
        }
      });
  }
};
