import React from "react";

const SignIn = React.lazy(() => import("./Pages/Authentication/AuthPage"));
const ActivateAccount = React.lazy(() => import("./Pages/Authentication/activatePage"));
const AdminPage = React.lazy(() => import("./Pages/Admin/index"));

const LandingPage = React.lazy(() => import("./Pages/landing/index"));
const ErrorPage = React.lazy(() => import("./Pages/Other/errorPage"));

const TermsAndConditions = React.lazy(() => import("./Pages/landing/termsAndConditions"));

const route = [
  { path: "/", exact: true, name: "Landing Page", component: LandingPage },
  { path: "/auth/:form/:param1?", exact: false, name: "Authentication", component: SignIn },
  // { path: "/auth/:form/:uid?/:token?", exact: false, name: "Authentication", component: SignIn },
  { path: "/activate/:uid/:token", exact: false, name: "Activation", component: ActivateAccount },
  { path: "/admin", exact: false, name: "Admin", component: AdminPage },
  { path: "/terms", exact: true, name: "Terms & conditions", component: TermsAndConditions },
  { path: "/error/:error?", exact: true, name: "Error Page", component: ErrorPage },
];

export default route;
