import {
  ADD_ITEM,
  ADD_ITEM_FAILURE,
  ADD_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_SUCCESS,
  EDIT_ITEM,
  EDIT_ITEM_FAILURE,
  EDIT_ITEM_SUCCESS,
  RETRIEVE_ITEM,
  RETRIEVE_ITEM_FAILURE,
  RETRIEVE_ITEM_SUCCESS,
} from "./actions";

const initialState = {
  data: {},
  addData: {},
  editData: {},
  removeData: {},
};

export const handlePayload = (state, action, key, payload) => {
  if (action.dataId) {
    return {
      ...state,
      [key]: {
        ...state[key],
        [action.dataType]: {
          [action.dataId]: {
            ...payload,
          },
        },
      },
    };
  }
  return {
    ...state,
    [key]: {
      ...state[key],
      [action.dataType]: {
        ...payload,
      },
    },
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_ITEM:
      return handlePayload(state, action.payload, "data", { isLoading: true });
    case RETRIEVE_ITEM_SUCCESS:
      return handlePayload(state, action.payload, "data", {
        isLoading: false,
        ...action.payload.data,
        err: {},
      });
    case RETRIEVE_ITEM_FAILURE:
      return handlePayload(state, action.payload, "data", {
        isLoading: false,
        ...action.payload.data,
        err: action.err,
      });

    case ADD_ITEM:
      return handlePayload(state, action.payload, "addData", { isLoading: true });
    case ADD_ITEM_SUCCESS:
      return handlePayload(state, action.payload, "addData", {
        isLoading: false,
        ...action.payload.data,
        err: null,
      });
    case ADD_ITEM_FAILURE:
      return handlePayload(state, action.payload, "addData", {
        isLoading: false,
        ...action.payload.data,
        err: action.payload.err,
      });

    case EDIT_ITEM:
      return handlePayload(state, action.payload, "editData", { isLoading: true });
    case EDIT_ITEM_SUCCESS:
      return handlePayload(state, action.payload, "editData", {
        isLoading: false,
        ...action.payload.data,
        err: null,
      });
    case EDIT_ITEM_FAILURE:
      return handlePayload(state, action.payload, "editData", {
        isLoading: false,
        ...action.payload.data,
        err: action.payload.err,
      });

    case DELETE_ITEM:
      return handlePayload(state, action.payload, "removeData", { isLoading: true });
    case DELETE_ITEM_SUCCESS:
      return handlePayload(state, action.payload, "removeData", {
        isLoading: false,
        ...action.payload.data,
        err: null,
      });
    case DELETE_ITEM_FAILURE:
      return handlePayload(state, action.payload, "removeData", {
        isLoading: false,
        ...action.payload.data,
        err: action.payload.err,
      });
    default:
      return state;
  }
};
