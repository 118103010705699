import { error, info, success } from "@pnotify/core";
import axios from "axios";
import DEMO from "../global/constant";
import axiosApiInstance from "../global/axios";

export class CustomError extends Error {
  constructor(response, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.response = response;
  }
}

export const RAISE_TICKET = "RAISE_TICKET";
export const RAISE_TICKET_SUCCESS = "RAISE_TICKET_SUCCESS";
export const RAISE_TICKET_FAILURE = "RAISE_TICKET_FAILURE";

const raiseTicketRequest = (payload) => ({
  type: RAISE_TICKET,
  payload,
});

const raiseTicketSuccess = (payload) => ({
  type: RAISE_TICKET_SUCCESS,
  payload,
});

const raiseTicketFailure = (payload) => ({
  type: RAISE_TICKET_FAILURE,
  payload,
});

export const raiseTicket = (payload) => async (dispatch, getState) => {
  const { ticketType, dataId, successMessage, errorMessage, isEnquiry } = payload;
  const {
    tickets: { raiseTicket },
  } = getState();

  const addedTicket =
    raiseTicket[ticketType] && raiseTicket[ticketType][dataId]
      ? raiseTicket[ticketType][dataId]
      : {};
  const isLoading = addedTicket.isLoading;

  if (addedTicket.fulfilledAt) {
    info({
      title: "Message",
      text: "item already added",
    });
  }

  if (!isLoading) {
    dispatch(raiseTicketRequest(payload));

    const url = isEnquiry ? `${DEMO.BASE_URL}/tickets/enquiry/` : `${DEMO.BASE_URL}/tickets/`;
    const client = isEnquiry ? axios : axiosApiInstance;

    await client
      .post(url, payload.payload)
      .then((res) => {
        if (res.status === 201) {
          dispatch(
            raiseTicketSuccess({
              ticketType,
              dataId,
              data: res.data,
            })
          );
          success({
            title: "Message",
            text: successMessage,
          });
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          let errors = err.response.data;
          if (err.response.status === 400 && Array.isArray(errors.details)) {
            errors = errors.details.map((det) => det.msg);
          }
          dispatch(
            raiseTicketFailure({
              ticketType,
              dataId,
              data: errors,
            })
          );
        } else {
          dispatch(
            raiseTicketFailure({
              ticketType,
              dataId,
              data: err,
            })
          );
        }
        error({
          title: "Message",
          text: errorMessage,
        });
      });
  }
};

export const LIST_TICKETS_REQUEST = "LIST_TICKETS_REQUEST";
export const LIST_TICKETS_REQUEST_SUCCESS = "LIST_TICKETS_REQUEST_SUCCESS";
export const LIST_TICKETS_REQUEST_FAILURE = "LIST_TICKETS_REQUEST_FAILURE";

const listTicketRequest = (payload) => ({
  type: LIST_TICKETS_REQUEST,
  payload,
});

const listTicketSuccess = (payload) => ({
  type: LIST_TICKETS_REQUEST_SUCCESS,
  payload,
});

const listTicketFailure = (payload) => ({
  type: LIST_TICKETS_REQUEST_FAILURE,
  payload,
});

export const listTickets = (payload) => async (dispatch, getState) => {
  const { ticketType, ticketId, ticketStatus } = payload;
  const {
    tickets: { tickets: allTickets },
  } = getState();

  const currentTickets = allTickets[ticketType] ? allTickets[ticketType] : {};
  if (allTickets.failedAt) {
    error({
      title: "Error",
      text: "There was an error displaying items on this page. Please reload to proceed.",
    });
    return;
  }

  if (allTickets.fulfilledAt) {
    return;
  }

  if (!currentTickets.isLoading) {
    dispatch(listTicketRequest(payload));
    let url = `${DEMO.BASE_URL}/tickets?ticketType=${ticketType}`;

    if (ticketId) url = `${url}&ticketId=${ticketId}`;
    if (ticketStatus) url = `${url}&ticketStatus=${ticketStatus}`;

    await axiosApiInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          let tickets = {};

          if (res.data.length > 0) {
            res.data.forEach((item) => {
              tickets = { ...tickets, [item.id]: item };
            });
          }

          dispatch(
            listTicketSuccess({
              ticketId: null,
              ticketType,
              data: tickets,
            })
          );
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch(
            listTicketFailure({
              ticketId,
              ticketType,
              err: err.response.data,
            })
          );
        } else {
          dispatch(
            listTicketFailure({
              ticketId,
              ticketType,
              err,
            })
          );
        }
      });
  }
};

export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAILURE = "UPDATE_TICKET_FAILURE";

const updateTicketRequest = (payload) => ({
  type: UPDATE_TICKET,
  payload,
});

const updateTicketSuccess = (payload) => ({
  type: UPDATE_TICKET_SUCCESS,
  payload,
});

const updateTicketFailure = (payload) => ({
  type: UPDATE_TICKET_FAILURE,
  payload,
});

export const updateTicket = (payload) => async (dispatch, getState) => {
  const { ticketType, dataId, errorMessage, successMessage } = payload;
  const {
    tickets: { editTicket },
  } = getState();

  const isLoading =
    editTicket[ticketType] &&
    editTicket[ticketType][dataId] &&
    editTicket[ticketType][dataId].isLoading;

  if (!isLoading) {
    dispatch(updateTicketRequest(payload));
    const url = `${DEMO.BASE_URL}/tickets/${dataId}/`;

    await axiosApiInstance
      .patch(url, payload.payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            updateTicketSuccess({
              ticketType,
              dataId,
              data: res.data,
            })
          );

          success({
            title: "Message",
            text: successMessage,
          });
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          let errors = err.response.data;
          const message = Array.isArray(err.response.data.message)
            ? err.response.data.message[0]
            : err.response.data.message || errorMessage;
          dispatch(
            updateTicketFailure({
              ticketType,
              dataId,
              data: errors,
            })
          );

          error({
            title: "Message",
            text: message,
          });
        } else {
          dispatch(
            updateTicketFailure({
              ticketType,
              dataId,
              data: err,
            })
          );
          if (errorMessage) {
            error({
              title: "Message",
              text: errorMessage,
            });
          }
        }
      });
  }
};

export const UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS";
export const UPDATE_TICKET_STATUS_SUCCESS = "UPDATE_TICKET_STATUS_SUCCESS";
export const UPDATE_TICKET_STATUS_FAILURE = "UPDATE_TICKET_STATUS_FAILURE";

const updateTicketStatusRequest = (payload) => ({
  type: UPDATE_TICKET_STATUS,
  payload,
});

const updateTicketStatusSuccess = (payload) => ({
  type: UPDATE_TICKET_STATUS_SUCCESS,
  payload,
});

const updateTicketStatusFailure = (payload) => ({
  type: UPDATE_TICKET_STATUS_FAILURE,
  payload,
});

export const updateTicketStatuses = (payload) => async (dispatch, getState) => {
  const { ticketType, dataId, errorMessage } = payload;
  const {
    tickets: { editTicket, tickets: currentTickets },
  } = getState();

  const isLoading =
    editTicket[ticketType] &&
    editTicket[ticketType][dataId] &&
    editTicket[ticketType][dataId].isLoading;
  if (!isLoading) {
    dispatch(updateTicketStatusRequest(payload));
    const url = `${DEMO.BASE_URL}/tickets/${dataId}/update_status/`;

    await axiosApiInstance
      .post(url, payload.payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            updateTicketStatusSuccess({
              ticketType,
              dataId,
              data: res.data.ticket,
              message: res.data.message,
            })
          );

          success({
            title: "Message",
            text: res.data.message,
          });
        } else {
          throw new CustomError(res);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          let message = Array.isArray(err.response.data.message)
            ? err.response.data.message[0]
            : err.response.data.message;
          message = message || "error updating the status of this ticket";
          dispatch(
            updateTicketStatusFailure({
              ticketType,
              dataId,
              data: currentTickets[ticketType].payload[dataId],
              message: message,
            })
          );
          error({
            title: "Message",
            text: message,
          });
        } else {
          dispatch(
            updateTicketStatusFailure({
              ticketType,
              dataId,
              data: currentTickets[ticketType].payload[dataId],
              message: errorMessage,
            })
          );

          error({
            title: "Message",
            text: errorMessage,
          });
        }
      });
  }
};
