import * as actionTypes from "./actionTypes";
import { FINISHED, REQUESTED } from "../../utils";

const initialState = {
  events: {},
  create: {},
  edit: {},
  delete: {},
  fetch: {},
  view: null,
};

const parseEvents = (events = []) => {
  return events.map(({ start, end, id, title, details }) => ({
    id,
    start: new Date(start),
    end: new Date(end),
    title,
    allDay: details && !!details.allDay,
    description: details ? details.description || "" : "",
  }));
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_FORM:
      return {
        ...state,
        [action.mode]: {},
      };
    case actionTypes.VIEW_EVENT:
      return {
        ...state,
        view: action.event,
      };

    case actionTypes.FETCH_EVENTS:
      const defaultFetchMessage = "Error fetching your calendar";
      if (action.state === REQUESTED) {
        return {
          ...state,
          fetch: {
            isLoading: true,
            message: {
              text: "Updating your calendar",
              variant: "success",
            },
            refreshEvents: false, // reset this flag to false if it had ben set to true
          },
          events: {
            [action.userId]: [],
          },
        };
      }

      if (action.state === FINISHED && action.response) {
        const data = action.response.data;
        const fetchMessage =
          action.response.status === 200
            ? {
                text: "Calendar updated successfully",
                variant: "success",
              }
            : {
                text: defaultFetchMessage,
                variant: "warning",
              };
        return {
          ...state,
          fetch: {
            isLoading: false,
            message: fetchMessage,
          },
          events: {
            [action.userId]: parseEvents(data || []),
          },
        };
      }

      return {
        ...state,
        fetch: {
          isLoading: false,
          message: {
            text: defaultFetchMessage,
            variant: "warning",
          },
        },
        events: {
          [action.userId]: [],
        },
      };

    case actionTypes.CREATE_EVENT:
      const defaultCreateMessage = "Error creating event, try again";
      if (action.state === REQUESTED) {
        return {
          ...state,
          create: {
            isLoading: true,
            message: {
              text: "Adding your event",
              variant: "success",
            },
            closeDialog: false,
            canCloseDialog: false,
          },
        };
      }

      if (action.state === FINISHED && action.response) {
        const createMessage =
          action.response.status === 201
            ? {
                text: "Event added successfully",
                variant: "success",
              }
            : {
                text: defaultCreateMessage,
                variant: "warning",
              };
        return {
          ...state,
          create: {
            isLoading: false,
            message: createMessage,
            closeDialog: action.response.status === 201,
            canCloseDialog: true,
          },
          fetch: {
            ...state.fetch,
            refreshEvents: action.response.status === 201,
          },
        };
      }

      return {
        ...state,
        create: {
          isLoading: false,
          message: {
            text: defaultCreateMessage,
            variant: "warning",
          },
          closeDialog: false,
          canCloseDialog: true,
        },
      };

    case actionTypes.EDIT_EVENT:
      const defaultEditMessage = "Error creating event, try again";
      if (action.state === REQUESTED) {
        return {
          ...state,
          edit: {
            isLoading: true,
            message: {
              text: "Editing event...",
              variant: "success",
            },
            closeDialog: false,
            canCloseDialog: false,
          },
        };
      }

      if (action.state === FINISHED && action.response) {
        const editMessage =
          action.response.status === 200
            ? {
                text: "Event edited successfully",
                variant: "success",
              }
            : {
                text: defaultEditMessage,
                variant: "warning",
              };
        return {
          ...state,
          edit: {
            isLoading: false,
            message: editMessage,
            closeDialog: action.response.status === 200,
            canCloseDialog: true,
          },
          fetch: {
            ...state.fetch,
            refreshEvents: action.response.status === 200,
          },
        };
      }

      return {
        ...state,
        edit: {
          isLoading: false,
          message: {
            text: defaultEditMessage,
            variant: "warning",
          },
          closeDialog: false,
          canCloseDialog: true,
        },
      };

    case actionTypes.DELETE_EVENT:
      const defaultDeleteMessage = "Error deleting event, try again";
      if (action.state === REQUESTED) {
        return {
          ...state,
          delete: {
            isLoading: true,
            message: {
              text: "Deleting event...",
              variant: "success",
            },
            closeDialog: false,
            canCloseDialog: true,
          },
        };
      }

      if (action.state === FINISHED && action.response) {
        const code = action.response.status;
        return {
          ...state,
          delete: {
            isLoading: false,
            message: {
              text: code === 204 ? "event deleted successfully" : defaultDeleteMessage,
              variant: code === 204 ? "success" : "warning",
            },
            closeDialog: code === 204,
            canCloseDialog: true,
          },
          fetch: {
            ...state.fetch,
            refreshEvents: code === 204,
          },
        };
      }

      return {
        ...state,
        delete: {
          isLoading: false,
          message: {
            text: defaultDeleteMessage,
            variant: "warning",
          },
          closeDialog: false,
          canCloseDialog: true,
        },
      };

    default:
      return state;
  }
};
