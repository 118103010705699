import {
  LIST_TICKETS_REQUEST,
  LIST_TICKETS_REQUEST_FAILURE,
  LIST_TICKETS_REQUEST_SUCCESS,
  RAISE_TICKET,
  RAISE_TICKET_FAILURE,
  RAISE_TICKET_SUCCESS,
  UPDATE_TICKET,
  UPDATE_TICKET_FAILURE,
  UPDATE_TICKET_STATUS,
  UPDATE_TICKET_STATUS_FAILURE,
  UPDATE_TICKET_STATUS_SUCCESS,
  UPDATE_TICKET_SUCCESS,
} from "./actions";

const initialState = {
  raiseTicket: {},
  tickets: {},
  editTicket: {},
};

const handlePayload = (state, keys, ticketType, ticketId, payload) => {
  const ret = {
    ...state,
  };
  keys.forEach((key) => {
    if (ticketId) {
      ret[key] = {
        ...state[key],
        [ticketType]: {
          ...state[key][ticketType],
          [ticketId]: {
            ...payload,
          },
        },
      };
    } else {
      ret[key] = {
        ...state[key],
        [ticketType]: {
          ...payload,
        },
      };
    }
  });
  return ret;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RAISE_TICKET:
      return handlePayload(
        state,
        ["raiseTicket"],
        action.payload.ticketType,
        action.payload.dataId,
        {
          payload: action.payload,
          isLoading: true,
        }
      );
    case RAISE_TICKET_SUCCESS:
      return handlePayload(
        state,
        ["raiseTicket", "tickets"],
        action.payload.ticketType,
        action.payload.dataId,
        {
          isLoading: false,
          fulfilledAt: new Date(),
          payload: action.payload.data,
        }
      );
    case RAISE_TICKET_FAILURE:
      return handlePayload(
        state,
        ["raiseTicket"],
        action.payload.ticketType,
        action.payload.dataId,
        {
          isLoading: false,
          failedAt: new Date(),
          payload: action.payload.data,
        }
      );

    case LIST_TICKETS_REQUEST:
      return handlePayload(state, ["tickets"], action.payload.ticketType, action.payload.ticketId, {
        isLoading: true,
        requestedAt: new Date(),
        payload: {},
      });
    case LIST_TICKETS_REQUEST_SUCCESS:
      return handlePayload(state, ["tickets"], action.payload.ticketType, action.payload.ticketId, {
        isLoading: false,
        fulfilledAt: new Date(),
        payload: action.payload.data,
        err: null,
      });
    case LIST_TICKETS_REQUEST_FAILURE:
      return handlePayload(state, ["tickets"], action.payload.ticketType, action.payload.ticketId, {
        isLoading: false,
        failedAt: new Date(),
        err: action.payload.err,
        payload: {},
      });

    case UPDATE_TICKET:
    case UPDATE_TICKET_STATUS:
      return handlePayload(
        state,
        ["editTicket"],
        action.payload.ticketType,
        action.payload.dataId,
        {
          payload: action.payload,
          isLoading: true,
          message: "Updating the status of the ticket",
        }
      );
    case UPDATE_TICKET_SUCCESS:
    case UPDATE_TICKET_STATUS_SUCCESS:
      return handlePayload(
        state,
        ["editTicket", "tickets"],
        action.payload.ticketType,
        action.payload.dataId,
        {
          isLoading: false,
          fulfilledAt: new Date(),
          payload: action.payload.data,
          message: action.payload.message || "ticket updated successfully",
        }
      );
    case UPDATE_TICKET_FAILURE:
    case UPDATE_TICKET_STATUS_FAILURE:
      return handlePayload(
        state,
        ["editTicket"],
        action.payload.ticketType,
        action.payload.dataId,
        {
          isLoading: false,
          failedAt: new Date(),
          payload: action.payload.data,
          message: action.payload.message,
        }
      );
    default:
      return state;
  }
};
