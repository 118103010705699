import {
  ADD__JOB_ITEM_SUCCESS,
  ADD_JOB_ITEM,
  ADD_JOB_ITEM_FAILURE,
  EDIT_JOB_ITEM,
  EDIT_JOB_ITEM_FAILURE,
  EDIT_JOB_ITEM_SUCCESS,
  RESET_ADD_NEW_JOB,
  RETRIEVE_JOB_ITEM,
  RETRIEVE_JOB_ITEM_FAILURE,
  RETRIEVE_JOB_ITEM_SUCCESS,
  RETRIEVE_JOB_STAFFING,
  RETRIEVE_JOB_STAFFING_FAILURE,
  RETRIEVE_JOB_STAFFING_SUCCESS,
} from "./actions";

export const handlePayload = (state, action, key, payload) => {
  if (action.dataId) {
    return {
      ...state,
      [key]: {
        ...state[key],
        [action.dataId]: {
          ...payload,
        },
      },
    };
  }
  return {
    ...state,
    [key]: {
      ...state[key],
      ...payload,
    },
  };
};

const initialState = {
  data: {},
  addData: {},
  editData: {},
  removeData: {},
  staffing: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_JOB_ITEM:
      return handlePayload(state, action.payload, "addData", { isLoading: true });
    case ADD__JOB_ITEM_SUCCESS:
      return handlePayload(state, action.payload, "addData", {
        isLoading: false,
        ...action.payload.data,
        err: null,
      });
    case ADD_JOB_ITEM_FAILURE:
      return handlePayload(state, action.payload, "addData", {
        isLoading: false,
        ...action.payload.data,
        err: action.payload.err,
      });
    case RESET_ADD_NEW_JOB:
      return {
        ...state,
        addData: {
          isLoading: false,
        },
      };
    case EDIT_JOB_ITEM:
      return handlePayload(state, action.payload, "editData", { isLoading: true });
    case EDIT_JOB_ITEM_SUCCESS:
      return handlePayload(state, action.payload, "editData", {
        isLoading: false,
        ...action.payload.data,
        err: null,
      });
    case EDIT_JOB_ITEM_FAILURE:
      return handlePayload(state, action.payload, "editData", {
        isLoading: false,
        ...action.payload.data,
        err: action.payload.err,
      });

    case RETRIEVE_JOB_ITEM:
      return handlePayload(state, action.payload, "data", { isLoading: true });
    case RETRIEVE_JOB_ITEM_SUCCESS:
      return handlePayload(state, action.payload, "data", {
        isLoading: false,
        ...action.payload.data,
        err: {},
      });
    case RETRIEVE_JOB_ITEM_FAILURE:
      return handlePayload(state, action.payload, "data", {
        isLoading: false,
        ...action.payload.data,
        err: action.err,
      });

    case RETRIEVE_JOB_STAFFING:
      return handlePayload(state, action.payload, "staffing", { isLoading: true });
    case RETRIEVE_JOB_STAFFING_SUCCESS:
      return handlePayload(state, action.payload, "staffing", {
        isLoading: false,
        ...action.payload.data,
        err: {},
      });
    case RETRIEVE_JOB_STAFFING_FAILURE:
      return handlePayload(state, action.payload, "staffing", {
        isLoading: false,
        ...action.payload.data,
        err: action.err,
      });

    default:
      return state;
  }
};
